import React, { useEffect, useState, useContext } from "react";

const TokenomicsBox = () => {

    return (
        <div className="relative w-full">
            <div
                className="absolute inset-0 rounded-[40px] bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
            ></div>
            <div
                className={`relative flex flex-col m-[3.5px] bg-[#ffffff] backdrop-blur-md rounded-[36.5px] px-[10px] md:px-[40px] py-[20px] md:py-[40px] bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
            >
                <div className="flex border-b border-b-[#FFFFFF99]">
                    <span className="w-5/12 text-[18px] leading-[28px] font-[600] text-white text-center border-r border-r-[#FFFFFF99]">Tokens Distribution</span>
                    <span className="w-3/12 text-[18px] leading-[28px] font-[600] text-white text-center border-r border-r-[#FFFFFF99]">Total Tokens</span>
                    <span className="w-4/12 text-[18px] leading-[28px] font-[600] text-white text-center ">% of Total Supply</span>
                </div>
                <div className="flex border-b border-b-[#FFFFFF99]">
                    <span className="w-5/12 text-[12px] md:text-[14px] leading-[28px] font-[600] text-white border-r border-r-[#FFFFFF99] pl-[10px]">Private sale(10k$ min)</span>
                    <span className="w-3/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center border-r border-r-[#FFFFFF99]">150,000,000</span>
                    <span className="w-4/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center ">2.50%</span>
                </div>
                <div className="flex border-b border-b-[#FFFFFF99]">
                    <span className="w-5/12 text-[12px] md:text-[14px] leading-[28px] font-[600] text-white border-r border-r-[#FFFFFF99] pl-[10px]">pre-sale</span>
                    <span className="w-3/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center border-r border-r-[#FFFFFF99]">300,000,000</span>
                    <span className="w-4/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center ">5.00%</span>
                </div>
                <div className="flex border-b border-b-[#FFFFFF99]">
                    <span className="w-5/12 text-[12px] md:text-[14px] leading-[28px] font-[600] text-white border-r border-r-[#FFFFFF99] pl-[10px]">Public sale/liquidity/CEX</span>
                    <span className="w-3/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center border-r border-r-[#FFFFFF99]">1,500,000,000</span>
                    <span className="w-4/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center ">25.00%</span>
                </div>
                <div className="flex border-b border-b-[#FFFFFF99]">
                    <span className="w-5/12 text-[12px] md:text-[14px] leading-[28px] font-[600] text-white border-r border-r-[#FFFFFF99] pl-[10px]">LP Rewards</span>
                    <span className="w-3/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center border-r border-r-[#FFFFFF99]">1,260,000,000</span>
                    <span className="w-4/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center ">21.00%</span>
                </div>
                <div className="flex border-b border-b-[#FFFFFF99]">
                    <span className="w-5/12 text-[12px] md:text-[14px] leading-[28px] font-[600] text-white border-r border-r-[#FFFFFF99] pl-[10px]">Reserve</span>
                    <span className="w-3/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center border-r border-r-[#FFFFFF99]">420,000,000</span>
                    <span className="w-4/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center ">7.00%</span>
                </div>
                <div className="flex border-b border-b-[#FFFFFF99]">
                    <span className="w-5/12 text-[12px] md:text-[14px] leading-[28px] font-[600] text-white border-r border-r-[#FFFFFF99] pl-[10px]">Ecosystem & development</span>
                    <span className="w-3/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center border-r border-r-[#FFFFFF99]">360,000,000</span>
                    <span className="w-4/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center ">6.00%</span>
                </div>
                <div className="flex border-b border-b-[#FFFFFF99]">
                    <span className="w-5/12 text-[12px] md:text-[14px] leading-[28px] font-[600] text-white border-r border-r-[#FFFFFF99] pl-[10px]">Marketing & airdrop</span>
                    <span className="w-3/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center border-r border-r-[#FFFFFF99]">900,000,000</span>
                    <span className="w-4/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center ">15.00%</span>
                </div>
                <div className="flex border-b border-b-[#FFFFFF99]">
                    <span className="w-5/12 text-[12px] md:text-[14px] leading-[28px] font-[600] text-white border-r border-r-[#FFFFFF99] pl-[10px]">Advisors / Early contributors / Partnerships</span>
                    <span className="w-3/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center border-r border-r-[#FFFFFF99]">240,000,000</span>
                    <span className="w-4/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center ">4.00%</span>
                </div>
                <div className="flex border-b border-b-[#FFFFFF99]">
                    <span className="w-5/12 text-[12px] md:text-[14px] leading-[28px] font-[600] text-white border-r border-r-[#FFFFFF99] pl-[10px]">Founders & team</span>
                    <span className="w-3/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center border-r border-r-[#FFFFFF99]">822,000,000</span>
                    <span className="w-4/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center ">13.70%</span>
                </div>
                <div className="flex border-b border-b-[#FFFFFF99]">
                    <span className="w-5/12 text-[12px] md:text-[14px] leading-[28px] font-[600] text-white border-r border-r-[#FFFFFF99] pl-[10px]">Liquidity/MMs</span>
                    <span className="w-3/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center border-r border-r-[#FFFFFF99]">48,000,000</span>
                    <span className="w-4/12 text-[12px] md:text-[14px] leading-[28px] font-[400] text-white text-center ">0.80%</span>
                </div>
            </div>
        </div>
    );
};

export default TokenomicsBox;
