import React, { useEffect, useState, useContext } from "react";

const RoadmapBox = () => {

    return (
        <>
            <div className="relative hidden w-full md:flex">
                <div className="w-full flex justify-between px-[40px] z-10">
                    <div className="flex flex-col gap-[10px] items-center">
                        <span className="text-[16px] leading-[24px] font-[700] text-white">Presale and Staking</span>
                        <div className="w-[2px] h-[50px] bg-white"></div>
                        <div className="relative w-[90px] h-[90px]">
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
                            ></div>
                            <div
                                className={`relative w-[86px] h-[86px] m-[2px] bg-[#ffffff] backdrop-blur-md rounded-full bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
                            ></div>
                        </div>
                        <div className="w-[2px] h-[50px] bg-[#0E0600]"></div>
                        <span className="text-[16px] leading-[24px] font-[700] text-[#0E0600]">Presale and Staking</span>
                    </div>
                    <div className="flex flex-col gap-[10px] items-center">
                        <span className="text-[16px] leading-[24px] font-[700] text-[#0E0600]">List on CEX</span>
                        <div className="w-[2px] h-[50px] bg-[#0E0600]"></div>
                        <div className="relative w-[90px] h-[90px]">
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
                            ></div>
                            <div
                                className={`relative w-[86px] h-[86px] m-[2px] bg-[#ffffff] backdrop-blur-md rounded-full bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
                            ></div>
                        </div>
                        <div className="w-[2px] h-[50px] bg-white"></div>
                        <span className="text-[16px] leading-[24px] font-[700] text-white">List on CEX</span>
                    </div>
                    <div className="flex flex-col gap-[10px] items-center">
                        <span className="text-[16px] leading-[24px] font-[700] text-white">List on DEX</span>
                        <div className="w-[2px] h-[50px] bg-white"></div>
                        <div className="relative w-[90px] h-[90px]">
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
                            ></div>
                            <div
                                className={`relative w-[86px] h-[86px] m-[2px] bg-[#ffffff] backdrop-blur-md rounded-full bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
                            ></div>
                        </div>
                        <div className="w-[2px] h-[50px] bg-[#0E0600]"></div>
                        <span className="text-[16px] leading-[24px] font-[700] text-[#0E0600]">List on DEX</span>
                    </div>
                    <div className="flex flex-col gap-[10px] items-center">
                        <span className="text-[16px] leading-[24px] font-[700] text-[#0E0600]">Create Layer-2</span>
                        <div className="w-[2px] h-[50px] bg-[#0E0600]"></div>
                        <div className="relative w-[90px] h-[90px]">
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
                            ></div>
                            <div
                                className={`relative w-[86px] h-[86px] m-[2px] bg-[#ffffff] backdrop-blur-md rounded-full bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
                            ></div>
                        </div>
                        <div className="w-[2px] h-[50px] bg-white"></div>
                        <span className="text-[16px] leading-[24px] font-[700] text-white">Create Layer-2</span>
                    </div>
                    <div className="flex flex-col gap-[10px] items-center">
                        <span className="text-[16px] leading-[24px] font-[700] text-white">Create CEX</span>
                        <div className="w-[2px] h-[50px] bg-white"></div>
                        <div className="relative w-[90px] h-[90px]">
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
                            ></div>
                            <div
                                className={`relative w-[86px] h-[86px] m-[2px] bg-[#ffffff] backdrop-blur-md rounded-full bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
                            ></div>
                        </div>
                        <div className="w-[2px] h-[50px] bg-[#0E0600]"></div>
                        <span className="text-[16px] leading-[24px] font-[700] text-[#0E0600]">Create CEX</span>
                    </div>
                    <div className="flex flex-col gap-[10px] items-center">
                        <span className="text-[16px] leading-[24px] font-[700] text-[#0E0600]">Get Memecoins To Thrive</span>
                        <div className="w-[2px] h-[50px] bg-[#0E0600]"></div>
                        <div className="relative w-[90px] h-[90px]">
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
                            ></div>
                            <div
                                className={`relative w-[86px] h-[86px] m-[2px] bg-[#ffffff] backdrop-blur-md rounded-full bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
                            ></div>
                        </div>
                        <div className="w-[2px] h-[50px] bg-white"></div>
                        <span className="text-[16px] leading-[24px] font-[700] text-white">Get Memecoins To Thrive</span>
                    </div>
                </div>
                <div className="absolute left-0 bottom-[120px] w-full h-[38px] bg-[#CF7D33] rounded-full"></div>
            </div>
            <div className="relative flex flex-col w-full md:hidden">
                <div className="w-full flex flex-col gap-[20px] py-[40px] z-10">
                    <div className="flex gap-[10px] justify-between items-center">
                        <span className="w-[120px] text-[16px] leading-[24px] font-[700] text-white">Presale and Staking</span>
                        <div className="relative w-[50px] h-[50px]">
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
                            ></div>
                            <div
                                className={`relative w-[46px] h-[46px] m-[2px] bg-[#ffffff] backdrop-blur-md rounded-full bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
                            ></div>
                        </div>
                        <span className="w-[120px] text-[16px] leading-[24px] font-[700] text-[#0E0600]">Presale and Staking</span>
                    </div>
                    <div className="flex gap-[10px] justify-between items-center">
                        <span className="w-[120px] text-[16px] leading-[24px] font-[700] text-[#0E0600]">List on CEX</span>
                        <div className="relative w-[50px] h-[50px]">
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
                            ></div>
                            <div
                                className={`relative w-[46px] h-[46px] m-[2px] bg-[#ffffff] backdrop-blur-md rounded-full bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
                            ></div>
                        </div>
                        <span className="w-[120px] text-[16px] leading-[24px] font-[700] text-white">List on CEX</span>
                    </div>
                    <div className="flex gap-[10px] justify-between items-center">
                        <span className="w-[120px] text-[16px] leading-[24px] font-[700] text-white">List on DEX</span>
                        <div className="relative w-[50px] h-[50px]">
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
                            ></div>
                            <div
                                className={`relative w-[46px] h-[46px] m-[2px] bg-[#ffffff] backdrop-blur-md rounded-full bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
                            ></div>
                        </div>
                        <span className="w-[120px] text-[16px] leading-[24px] font-[700] text-[#0E0600]">List on DEX</span>
                    </div>
                    <div className="flex gap-[10px] justify-between items-center">
                        <span className="w-[120px] text-[16px] leading-[24px] font-[700] text-[#0E0600]">Create Layer-2</span>
                        <div className="relative w-[50px] h-[50px]">
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
                            ></div>
                            <div
                                className={`relative w-[46px] h-[46px] m-[2px] bg-[#ffffff] backdrop-blur-md rounded-full bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
                            ></div>
                        </div>
                        <span className="w-[120px] text-[16px] leading-[24px] font-[700] text-white">Create Layer-2</span>
                    </div>
                    <div className="flex gap-[10px] justify-between items-center">
                        <span className="w-[120px] text-[16px] leading-[24px] font-[700] text-white">Create CEX</span>
                        <div className="relative w-[50px] h-[50px]">
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
                            ></div>
                            <div
                                className={`relative w-[46px] h-[46px] m-[2px] bg-[#ffffff] backdrop-blur-md rounded-full bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
                            ></div>
                        </div>
                        <span className="w-[120px] text-[16px] leading-[24px] font-[700] text-[#0E0600]">Create CEX</span>
                    </div>
                    <div className="flex gap-[10px] justify-between items-center">
                        <span className="w-[120px] text-[16px] leading-[24px] font-[700] text-[#0E0600]">Get Memecoins To Thrive</span>
                        <div className="relative w-[50px] h-[50px]">
                            <div
                                className="absolute inset-0 rounded-full bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
                            ></div>
                            <div
                                className={`relative w-[46px] h-[46px] m-[2px] bg-[#ffffff] backdrop-blur-md rounded-full bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
                            ></div>
                        </div>
                        <span className="w-[120px] text-[16px] leading-[24px] font-[700] text-white">Get Memecoins To Thrive</span>
                    </div>
                </div>
                <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
                    <div className="w-[20px] h-full bg-[#CF7D33] rounded-full"></div>
                </div>
            </div>
        </>
    );
};

export default RoadmapBox;
