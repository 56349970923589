import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";

import NoPage from "./pages/NoPage";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { bsc, bscTestnet } from 'viem/chains';
import { publicProvider } from "wagmi/providers/public";
import { infuraProvider } from "wagmi/providers/infura";

import { connectorsForWallets, RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { metaMaskWallet, walletConnectWallet } from "@rainbow-me/rainbowkit/wallets";

import { PresaleStakingProvider } from "./context/presalestaking-context";

let chain = bsc;

chain = {
  id: chain.id,
  name: chain.name,
  nativeCurrency: chain.nativeCurrency,
  rpcUrls: {
    infura: chain.rpcUrls.infura,
    // alchemy: {
    //   http: ['https://eth-mainnet.g.alchemy.com/v2'],
    //   webSocket: ['wss://eth-mainnet.g.alchemy.com/v2'],
    // },
    public: {
      http: ['https://bsc.blockpi.network/v1/rpc/public'],
      webSocket: ['wss://bsc-rpc.publicnode.com'],
    },
  },
  blockExplorers: chain.blockExplorers,
  contracts: chain.contracts,
}

const customProvider = chain.rpcUrls.public?.http?.[0] || chain.rpcUrls.infura.http?.[0];
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [chain],
  [
    infuraProvider({ apiKey: '4b44b11c313a412fb3f7d128b9e3f1b6`' }),
    publicProvider({ rpc: () => customProvider }),
  ]
)

const walletsOptions = { chains: chains, projectId: "99f767528ce98d086e188781651334a9" };
const wallets = [
  metaMaskWallet({ ...walletsOptions, shimDisconnect: true }),
  walletConnectWallet({
    ...walletsOptions,
    shimDisconnect: true,
    projectId: walletsOptions.projectId,
    chains: chains.map(chain => ({
      ...chain,
      rpcUrls: {
        ...chain.rpcUrls,
        default: {
          http: [chain.rpcUrls.public?.http?.[0] || chain.rpcUrls.infura?.http?.[0] || ''],
        },
      },
    })),
  }),
];

const wagmiConnectors = connectorsForWallets([
  {
    groupName: "Supported Wallets",
    wallets,
  },
]);

const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
  connectors: wagmiConnectors
})

export default function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <WagmiConfig config={config}>
    <RainbowKitProvider
      chains={chains}
      modalSize="compact"
      theme={darkTheme()}
    >
      <PresaleStakingProvider>
        <App />
      </PresaleStakingProvider>
    </RainbowKitProvider>
  </WagmiConfig>
  , document.getElementById("root")
);
