import React, { useEffect, useState, useContext } from "react";

const AboutBox = () => {

    return (
        <div className="relative w-full">
            <div
                className="absolute inset-0 rounded-[40px] bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
            ></div>
            <div
                className={`relative flex flex-col gap-[40px] m-[3.5px] bg-[#ffffff] backdrop-blur-md rounded-[36.5px] p-[20px] md:p-[80px] bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
            >
                <div className="flex justify-center">
                    <span className="text-[40px] md:text-[64px] leading-[96px] font-[600] text-white">About</span>
                </div>
                <div className="flex flex-col gap-[20px]">
                    <span className="text-[20px] md:text-[24px] leading-[36px] font-[400] text-white text-center">In the heart of a mythical jungle, where legends take shape, emerges the mother of all meme coins—a token of boundless potential.</span>
                    <span className="ext-[20px] md:text-[24px] leading-[36px] font-[400] text-white text-center">Born from the vision of creating the ultimate infrastructure for all tokens, it carries whispers of a journey to become the fastest and most affordable path for growth.</span>
                    <span className="ext-[20px] md:text-[24px] leading-[36px] font-[400] text-white text-center">As its roots deepen and its branches spread, this project is destined to evolve into a network unlike any other, one that stands tall and mighty, paving the way for a new realm of possibilities.</span>
                </div>
            </div>
        </div>
    );
};

export default AboutBox;
