import React, { useEffect, useState, useContext } from "react";
import { CustomConnectButton } from "./CustomConnectButton";
import { useAccount, useBalance } from 'wagmi';
import { waitForTransaction } from "@wagmi/core";
import { parseEther, formatUnits } from 'viem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { PresaleStakingContext } from "../context/presalestaking-context";

import { PRESALE_END_TIME } from "../config";

import { toast } from "react-toastify";

const presaleEndTime = PRESALE_END_TIME;

const PresaleBox = () => {

    const { address } = useAccount();
    const balance = useBalance({ address });

    const presaleStakingContext = useContext(PresaleStakingContext);

    const [totalSold, setTotalSold] = useState(0);
    const [presalePrice, setPresalePrice] = useState(0);
    const [presaleRemainingTokens, setPresaleRemainingTokens] = useState(0);

    const [buyAmount, setBuyAmount] = useState("0");
    const [buyPending, setBuyPending] = useState(false);

    const [currentTime, setCurrentTime] = useState(Date.now());

    useEffect(() => {
        if (!presaleStakingContext.isLoading) {
            setTotalSold(formatUnits(presaleStakingContext.totalSold ? presaleStakingContext.totalSold : 0, 18));
            setPresalePrice(formatUnits(presaleStakingContext.presalePrice ? presaleStakingContext.presalePrice : 0, 18));
            setPresaleRemainingTokens(formatUnits(presaleStakingContext.presaleRemainingTokens ? presaleStakingContext.presaleRemainingTokens : 0, 18));
        }
    }, [address, presaleStakingContext])

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const onBuy = async () => {
        setBuyPending(true);
        try {
            if (address) {
                console.log(address, buyAmount, parseEther(buyAmount), Number(balance.data.formatted));
                if (Number(buyAmount) <= Number(balance.data.formatted)) {
                    const resultBuy = await presaleStakingContext.executeBuy(
                        [],
                        parseEther(buyAmount),
                        address
                    );
                    const txData = await waitForTransaction({ hash: resultBuy });
                    if (txData && txData.status === "success") {
                        toast.success("Buy successfully");
                        presaleStakingContext.refetchTotalSold();
                        presaleStakingContext.refetchPresaleRemainingTokens();
                    } else {
                        toast.error("Error! Buy is failed.");
                    }
                } else {
                    toast.error(`Buy amount must be less than ${balance.data.formatted} !`);
                }
            } else {
                toast.error("Connect Wallet First!");
            }
        } catch (error) {
            console.log(error);
            toast.error("Failed");
        }
        setBuyPending(false);
    }

    return (
        <div className="relative w-full md:w-[560px]">
            <div
                className="absolute inset-0 rounded-[40px] bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
            ></div>
            <div
                className={`relative flex flex-col gap-[10px] m-[3.5px] bg-[#ffffff] backdrop-blur-md rounded-[36.5px] p-[20px] md:p-[40px] bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
            >
                <div className="flex justify-center">
                    <span className="text-[28px] leading-[42px] font-[600] text-white">$SHRX Pre-sale</span>
                </div>
                <div className="flex flex-col bg-[#FFFFFF33] rounded-[12px] border border-[#00000020] drop-shadow-lg overflow-hidden">
                    <div className="flex justify-center bg-[#FFFFFF33] p-[10px] drop-shadow-lg">
                        <span className="text-[18px] leading-[24px] font-[400] text-white">Next Price Increase in:</span>
                    </div>
                    <div className="flex gap-[10px] md:gap-[40px] justify-center p-[10px]">
                        <div className="flex flex-col gap-[10px] items-center">
                            <span className="text-[16px] leading-[24px] font-[400] text-white">Days</span>
                            <span className="text-[16px] leading-[24px] font-[600] text-white">{presaleEndTime - currentTime < 0 ? 0 : Math.floor((presaleEndTime - currentTime) / (1000 * 60 * 60 * 24))}</span>
                        </div>
                        <div className="flex flex-col gap-[10px] items-center">
                            <span className="text-[16px] leading-[24px] font-[400] text-white">Hours</span>
                            <span className="text-[16px] leading-[24px] font-[600] text-white">{presaleEndTime - currentTime < 0 ? 0 : Math.floor(((presaleEndTime - currentTime) / (1000 * 60 * 60)) % 24)}</span>
                        </div>
                        <div className="flex flex-col gap-[10px] items-center">
                            <span className="text-[16px] leading-[24px] font-[400] text-white">Minutes</span>
                            <span className="text-[16px] leading-[24px] font-[600] text-white">{presaleEndTime - currentTime < 0 ? 0 : Math.floor((presaleEndTime - currentTime) / (1000 * 60) % 60)}</span>
                        </div>
                        <div className="flex flex-col gap-[10px] items-center">
                            <span className="text-[16px] leading-[24px] font-[400] text-white">Seconds</span>
                            <span className="text-[16px] leading-[24px] font-[600] text-white">{presaleEndTime - currentTime < 0 ? 0 : Math.floor((presaleEndTime - currentTime) / 1000 % 60)}</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-[10px]">
                    <div className="flex justify-between">
                        <span className="text-[16px] leading-[24px] font-[400] text-white">{Number(totalSold).toFixed(0)} $SHRX</span>
                        <span className="text-[16px] leading-[24px] font-[400] text-white">{Number(totalSold) + Number(presaleRemainingTokens)} $SHRX</span>
                    </div>
                    <div className="relative w-full h-[20px] rounded-[20px] bg-[#FFFFFF99] border border-[#00000020] drop-shadow-lg">
                        <div className="absolute inset-0 rounded-[20px] bg-gradient-to-r from-[#CF7D33] to-[#FAB373]" style={{ width: `${(Number(totalSold) / (Number(totalSold) + Number(presaleRemainingTokens)) * 100).toFixed(0)}%`, height: "100%" }}></div>
                    </div>
                </div>
                <div className="flex flex-col gap-[20px] items-center">
                    <span className="text-[24px] leading-[36px] font-[400] text-white">1 $SHRX = {presalePrice} BNB</span>
                    {/* <div className="flex gap-[40px] justify-center">
                        <span className="text-[20px] leading-[30px] font-[400] text-white">Bought $SHRX = x</span>
                        <span className="text-[20px] leading-[30px] font-[400] text-white">Stakable $SHRX = x</span>
                    </div> */}
                </div>
                <div className="flex flex-col gap-[10px]">
                    <div className="flex gap-[20px] items-center">
                        <span className="text-[14px] leading-[22px] font-[400] text-white">Buy with</span>
                        <div className="flex gap-[10px] items-center px-[20px] py-[10px] bg-[#FFFFFF33] rounded-[12px] border border-[#00000020] drop-shadow-lg">
                            <img className="w-[26px] h-[26px] object-contain rounded-full" src="images/bnb.png" />
                            <span className="text-[16px] leading-[24px] font-[400] text-white">BNB</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[20px] p-[20px] bg-[#FFFFFF33] rounded-[12px] border border-[#00000020] drop-shadow-lg">
                        <div className="flex justify-between">
                            <span className="text-[12px] leading-[18px] font-[400] text-white">Send</span>
                            {balance.data && balance.data.formatted && <span onClick={() => { setBuyAmount(balance.data.formatted) }} className="text-[12px] leading-[18px] font-[400] text-white cursor-pointer">Max: {Number(balance.data.formatted).toFixed(3)}</span>}
                            <span className="text-[12px] leading-[18px] font-[400] text-white">Receive (estimated)</span>
                        </div>
                        <div className="flex justify-between">
                            <input value={buyAmount} onChange={e => setBuyAmount(e.target.value)} type="number" className="w-[100px] bg-transparent focus:outline-none text-[20px] leading-[30px] font-[400] text-white" />
                            {/* <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25.9338 19.3565L25.9338 8.40871C25.9338 3.65335 23.1397 0.81842 18.4528 0.81842L7.67551 0.81842C2.97573 0.818419 0.18162 3.65335 0.181619 8.40871L0.181619 19.3434C0.181619 24.0988 2.97573 26.9337 7.66264 26.9337L18.4528 26.9337C23.1397 26.9468 25.9338 24.1119 25.9338 19.3565ZM6.61967 12.5631C6.49091 12.5631 6.37503 12.537 6.24626 12.4847C6.0145 12.3802 5.82135 12.1973 5.71835 11.9491C5.66684 11.8315 5.64109 11.7009 5.64109 11.5702C5.64109 11.4396 5.66684 11.322 5.71835 11.1914C5.76985 11.0738 5.83423 10.9693 5.92436 10.8778L9.72282 7.02391C10.0962 6.64505 10.7143 6.64505 11.0877 7.02391C11.4611 7.40277 11.4611 8.02985 11.0877 8.40871L8.93737 10.5904L19.4958 10.5904C20.0237 10.5904 20.4615 11.0346 20.4615 11.5702C20.4615 12.1059 20.0237 12.5631 19.4958 12.5631L6.61967 12.5631ZM15.0149 20.7282C14.8218 20.5323 14.7316 20.2841 14.7316 20.0358C14.7316 19.7876 14.8218 19.5394 15.0149 19.3434L17.1652 17.1617L6.61967 17.1617C6.09175 17.1617 5.65397 16.7175 5.65397 16.1819C5.65397 15.6463 6.09175 15.2021 6.61967 15.2021L19.4958 15.2021C19.6245 15.2021 19.7404 15.2282 19.8692 15.2805C20.101 15.385 20.2941 15.5679 20.3971 15.8161C20.5001 16.0513 20.5001 16.3256 20.3971 16.5608C20.3456 16.6783 20.2812 16.7829 20.1911 16.8743L16.3926 20.7282C16.0064 21.1071 15.3883 21.1071 15.0149 20.7282Z" fill="white" />
                            </svg> */}

                            <span className="text-[20px] leading-[30px] font-[400] text-white">{(Number(buyAmount) / Number(presalePrice)).toFixed(0)}</span>
                        </div>
                    </div>
                </div>
                {!address ?
                    <CustomConnectButton /> :
                    <button disabled={buyPending} className="w-full flex gap-[10px] justify-center items-center bg-[#000000] px-[20px] py-[10px] rounded-[12px] drop-shadow-lg" onClick={onBuy}>
                        <span className="text-[16px] leading-[26px] font-[400] text-white">Buy $SHRX</span>
                        {buyPending && <FontAwesomeIcon className="text-white animate-spin" icon={faSpinner} />}
                    </button>
                }
            </div>
        </div>
    );
};

export default PresaleBox;
