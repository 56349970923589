import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { CustomConnectButton } from "../components/CustomConnectButton";

const Header = () => {

    const navigate = useNavigate();
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return (
        <>
            <div className="fixed w-full h-[80px] flex justify-center rounded-b-[12px] bg-[#ffffff33] backdrop-blur-sm z-20">
                <div className="max-w-[1400px] w-full flex justify-between px-[20px] py-[20px]">
                    <a href="#"><img className="object-contain h-full" src="images/icon.png" /></a>
                    <div className="hidden md:flex gap-[40px] md:gap-[80px] items-center">
                        <div className="flex gap-[40px] items-center">
                            <button onClick={(e) => { window.location.replace("/#tokenomics") }} className="text-[18px] leading-[20px] font-[600] text-white">Tokenomics</button>
                            <button onClick={(e) => { window.location.replace("/#about") }} className="text-[18px] leading-[20px] font-[600] text-white">About</button>
                            <button onClick={(e) => { window.location.replace("/#staking") }} className="text-[18px] leading-[20px] font-[600] text-white">Staking</button>
                            <button onClick={(e) => { window.location.replace("/#roadmap") }} className="text-[18px] leading-[20px] font-[600] text-white">Roadmap</button>
                        </div>
                        <div className="flex gap-[20px] items-center">
                            <a href="https://t.me/sherexcoin" target="_blank" rel="noopener noreferrer"><svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 0C6.26875 0 0 6.26875 0 14C0 21.7313 6.26875 28 14 28C21.7313 28 28 21.7313 28 14C28 6.26875 21.7313 0 14 0ZM20.6875 9.60313L18.3781 20.3016C18.2188 21.0758 17.75 21.2688 17.1031 20.9063L13.65 18.375L11.9844 19.9563C11.7938 20.1469 11.6344 20.3063 11.2563 20.3063L11.5156 16.7844L18.0938 10.8625C18.3875 10.6031 18.025 10.4594 17.625 10.7188L9.54688 15.8766L6.13438 14.8438C5.37813 14.5813 5.36094 14.0422 6.28438 13.6797L19.7031 8.49688C20.3375 8.28594 20.8906 8.68438 20.6875 9.60313Z" fill="white"/>
                            </svg></a>
                            <a href="https://x.com/sherexcoin" target="_blank" rel="noopener noreferrer"><svg width="22" height="20" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.895769 0L12.3422 15.3049L0.823486 27.7485H3.41588L13.5005 16.854L21.6485 27.7485H30.4705L18.3801 11.5827L29.1016 0H26.5092L17.2218 10.0336L9.71778 0H0.895769ZM4.70808 1.90956H8.76093L26.6576 25.8386H22.6048L4.70808 1.90956Z" fill="white" />
                            </svg></a>
                        </div>
                    </div>
                    <div className="w-[250px] hidden md:flex pl-[10px]">
                        <CustomConnectButton />
                    </div>
                    <button onClick={(e) => { setShowMobileMenu(true); }} className="flex md:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <path data-name="Path 205" d="M0 0h32v32H0z" fill="none" />
                            <path data-name="Path 206" d="M3 22.852h24.074v-2.809H3zm0-7.022h24.074v-2.808H3zM3 6v2.809h24.074V6z" transform="translate(.963 1.574)" fill="#fff" />
                        </svg>
                    </button>
                </div>
            </div>
            {showMobileMenu &&
                <div className={`font-patrick fixed top-0 left-0 w-[100vw] h-[100vh] flex justify-end items-start bg-[#ffffff30] backdrop-blur-md p-[40px] z-50`}>
                    <div
                        className="fixed inset-0 transition-opacity"
                        onClick={() => { setShowMobileMenu(false); }}
                    />
                    <div className="bg-[#00000030] backdrop-blur-sm flex flex-col border-2 border-white rounded-[10px] py-[20px] px-[10px]" >
                        <button onClick={(e) => { setShowMobileMenu(false); window.location.replace("/#tokenomics") }} className={`text-[18px] text-[#fff] border-b border-white py-[10px]`}>Tokenomics</button>
                        <button onClick={(e) => { setShowMobileMenu(false); window.location.replace("/#about") }} className={`text-[18px] text-[#fff] border-b border-white py-[10px]`}>About</button>
                        <button onClick={(e) => { setShowMobileMenu(false); window.location.replace("/#staking") }} className={`text-[18px] text-[#fff] border-b border-white py-[10px]`}>Staking</button>
                        <button onClick={(e) => { setShowMobileMenu(false); window.location.replace("/#roadmap") }} className={`text-[18px] text-[#fff] border-b border-white py-[10px]`}>Roadmap</button>
                        <div className="flex justify-center py-[10px]">
                            <CustomConnectButton />
                        </div>
                    </div>
                </div>
            }
        </>
        /* <div className="font-helvetica absolute top-0 left-0 w-[100vw] h-[160px] flex gap-[20px] md:gap-[40px] justify-between items-center px-[10px] md:px-[60px] md:px-[120px] z-40">
            <span onClick={(e) => { navigate(`/${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className="text-[50px] font-bold text-[#000] cursor-pointer">Schöpf</span>
            <div className="hidden md:flex gap-[80px] items-center">
                <div className="flex gap-[80px]">
                    <button onClick={(e) => { navigate(`/buynow${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[14px] ${location.pathname == '/buynow' ? 'font-bold' : ''} text-[#000]`}>Buynow</button>
                    <button onClick={(e) => { navigate(`/dashboard${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[14px] ${location.pathname == '/dashboard' ? 'font-bold' : ''} text-[#000]`}>Dashboard</button>
                    <button onClick={(e) => { navigate(`/referral${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[14px] ${location.pathname == '/referral' ? 'font-bold' : ''} text-[#000]`}>Referral</button>
                    {address && address.toLowerCase() == ADMIN_ADDRESS.toLowerCase() && <button onClick={(e) => { navigate(`/icoadmin${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[14px] ${location.pathname == '/icoadmin' ? 'font-bold' : ''} text-[#000]`}>Ico Admin</button>}
                </div>
                <CustomConnectButton />
            </div>
            <button onClick={(e) => { setShowMobileMenu(true); }} className="flex md:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                    <path data-name="Path 205" d="M0 0h32v32H0z" fill="none" />
                    <path data-name="Path 206" d="M3 22.852h24.074v-2.809H3zm0-7.022h24.074v-2.808H3zM3 6v2.809h24.074V6z" transform="translate(.963 1.574)" fill="#000" />
                </svg>
            </button>
        </div >
        {showMobileMenu &&
            <div className={`font-patrick fixed top-0 left-0 w-[100vw] h-[100vh] flex justify-end items-start bg-[#ffffff30] backdrop-blur-md p-[40px] z-50`}>
                <div
                    className="fixed inset-0 transition-opacity"
                    onClick={() => { setShowMobileMenu(false); }}
                />
                <div className="bg-[#00000030] backdrop-blur-sm flex flex-col border-2 border-white rounded-[10px] py-[20px] px-[10px]" >
                    <button onClick={(e) => { setShowMobileMenu(false); navigate(`/buynow${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[20px] ${location.pathname == '/buynow' ? 'font-bold' : ''} text-[#000] border-b border-white py-[10px]`}>Buynow</button>
                    <button onClick={(e) => { setShowMobileMenu(false); navigate(`/dashboard${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[20px] ${location.pathname == '/dashboard' ? 'font-bold' : ''} text-[#000] border-b border-white py-[10px]`}>Dashboard</button>
                    <button onClick={(e) => { setShowMobileMenu(false); navigate(`/referral${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[20px] ${location.pathname == '/referral' ? 'font-bold' : ''} text-[#000] border-b border-white py-[10px]`}>Referral</button>
                    {address && address.toLowerCase() == ADMIN_ADDRESS.toLowerCase() && <button onClick={(e) => { setShowMobileMenu(false); navigate(`/icoadmin${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[20px] ${location.pathname == '/icoadmin' ? 'font-bold' : ''} text-[#000] border-b border-white py-[10px]`}>Ico Admin</button>}
                    <div className="flex justify-center py-[10px]">
                        <CustomConnectButton />
                    </div>
                </div>
            </div>
        } */
    );
}

export default Header;