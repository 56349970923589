import React, { useEffect, useState, useContext } from "react";
import { CustomConnectButton } from "./CustomConnectButton";
import { useAccount } from "wagmi";
import { waitForTransaction } from "@wagmi/core";
import { parseEther, formatUnits } from 'viem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { PresaleStakingContext, stakingAddress } from "../context/presalestaking-context";

import { toast } from "react-toastify";

const stakeDetails = [
    { stakePercentLabel: "30%", stakePercent: 30, stakePeriodLabel: "6 months", stakePeriod: 6 },
    { stakePercentLabel: "100%", stakePercent: 100, stakePeriodLabel: "12 months", stakePeriod: 12 },
    { stakePercentLabel: "230%", stakePercent: 230, stakePeriodLabel: "24 months", stakePeriod: 24 },
    { stakePercentLabel: "400%", stakePercent: 400, stakePeriodLabel: "36 months", stakePeriod: 36 },
];

const StakingBox = () => {

    const { address } = useAccount();

    const presaleStakingContext = useContext(PresaleStakingContext);

    const [stakingRemainingTokens, setStakingRemainingTokens] = useState(0);
    const [stakingTokenBalance, setStakingTokenBalance] = useState(0);
    const [stakingStatus, setStakingStatus] = useState();
    const [accountTokenBalance, setAccountTokenBalance] = useState(0);
    const [stakingAllowance, setStakingAllowance] = useState(0);

    const [stakeAmount, setStakeAmount] = useState("0");
    const [stakePending, setStakePending] = useState(false);
    const [unstakePending, setUnstakePending] = useState(false);
    const [approvePending, setApprovePending] = useState(false);

    const [selectedStaking, setSelectedStaking] = useState(0);

    const [currentTime, setCurrentTime] = useState(Date.now());

    useEffect(() => {
        if (!presaleStakingContext.isLoading) {
            setStakingRemainingTokens(formatUnits(presaleStakingContext.stakingRemainingTokens ? presaleStakingContext.stakingRemainingTokens : 0, 18));
            setStakingTokenBalance(formatUnits(presaleStakingContext.stakingTokenBalance ? presaleStakingContext.stakingTokenBalance : 0, 18));
            setStakingStatus(presaleStakingContext.stakingStatus);
            console.log(presaleStakingContext.stakingStatus)
            setAccountTokenBalance(formatUnits(presaleStakingContext.accountTokenBalance ? presaleStakingContext.accountTokenBalance : 0, 18));
            setStakingAllowance(formatUnits(presaleStakingContext.stakingAllowance ? presaleStakingContext.stakingAllowance : 0, 18));
        }
    }, [address, presaleStakingContext])

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(Date.now())
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const onApprove = async () => {
        setApprovePending(true);
        try {
            if (address) {
                console.log(address, stakeAmount, parseEther(stakeAmount));
                const resultApprove = await presaleStakingContext.executeApprove(
                    [stakingAddress, parseEther((Number(stakeAmount)).toFixed(0))],
                    address
                );
                const txData = await waitForTransaction({ hash: resultApprove });
                if (txData && txData.status === "success") {
                    toast.success("Approve successfully");
                    presaleStakingContext.refetchStakingAllowance(address, stakingAddress);
                } else {
                    toast.error("Error! Approve is failed.");
                }
            } else {
                toast.error("Connect Wallet First!");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
        setApprovePending(false);
    }

    const onStake = async () => {
        setStakePending(true);
        try {
            if (address) {
                console.log(address, stakeAmount, parseEther(stakeAmount), stakeDetails[selectedStaking].stakePercentLabel);
                if (Number(stakeAmount) <= Number(accountTokenBalance)) {
                    const resultStake = await presaleStakingContext.executeStake(
                        [parseEther(stakeAmount), stakeDetails[selectedStaking].stakePeriod * 30 * 24 * 60 * 60],
                        address
                    );
                    const txData = await waitForTransaction({ hash: resultStake });
                    if (txData && txData.status === "success") {
                        toast.success("Stake successfully");
                        presaleStakingContext.refetchStakingRemainingTokens();
                        presaleStakingContext.refetchStakingTokenBalance();
                        presaleStakingContext.refetchStakingStatus();
                    } else {
                        toast.error("Error! Stake is failed.");
                    }
                } else {
                    toast.error(`Stake amount must be less than ${accountTokenBalance} !`);
                }
            } else {
                toast.error("Connect Wallet First!");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
        setStakePending(false);
    }

    const onUnstake = async () => {
        setUnstakePending(true);
        try {
            if (address) {
                console.log(address);
                const resultUnstake = await presaleStakingContext.executeUnstake(
                    [],
                    address
                );
                const txData = await waitForTransaction({ hash: resultUnstake });
                if (txData && txData.status === "success") {
                    toast.success("Unstake successfully");
                    presaleStakingContext.refetchStakingRemainingTokens();
                    presaleStakingContext.refetchStakingTokenBalance();
                    presaleStakingContext.refetchStakingStatus();
                } else {
                    toast.error("Error! Unstake is failed.");
                }
            } else {
                toast.error("Connect Wallet First!");
            }
        } catch (error) {
            console.log(error);
            toast.error("Failed");
        }
        setUnstakePending(false);
    }

    return (
        <div className="relative w-full">
            <div
                className="absolute inset-0 rounded-[40px] bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
            ></div>
            <div
                className={`relative flex flex-col gap-[20px] m-[3.5px] bg-[#ffffff] backdrop-blur-md rounded-[36.5px] p-[20px] md:p-[40px] bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
            >
                <div className="flex justify-center">
                    <span className="text-[28px] leading-[42px] font-[600] text-white">$SHRX Staking</span>
                </div>
                <div className="flex flex-col bg-[#FFFFFF33] rounded-[12px] border border-[#00000020] drop-shadow-lg overflow-hidden">
                    <div className="flex justify-center bg-[#FFFFFF33] p-[10px] drop-shadow-lg">
                        <span className="text-[18px] leading-[24px] font-[400] text-white">Available To Unstake in:</span>
                    </div>
                    <div className="flex gap-[10px] md:gap-[40px] justify-center p-[10px]">
                        <div className="flex flex-col gap-[10px] items-center">
                            <span className="text-[16px] leading-[24px] font-[400] text-white">Days</span>
                            {stakingStatus && <span className="text-[16px] leading-[24px] font-[600] text-white">{(currentTime + Number(stakingStatus[3]) * 1000) - currentTime < 0 ? 0 : Math.floor(((currentTime + Number(stakingStatus[3]) * 1000) - currentTime) / (1000 * 60 * 60 * 24))}</span>
                            }
                        </div>
                        <div className="flex flex-col gap-[10px] items-center">
                            <span className="text-[16px] leading-[24px] font-[400] text-white">Hours</span>
                            {stakingStatus && <span className="text-[16px] leading-[24px] font-[600] text-white">{(currentTime + Number(stakingStatus[3]) * 1000) - currentTime < 0 ? 0 : Math.floor((((currentTime + Number(stakingStatus[3]) * 1000) - currentTime) / (1000 * 60 * 60)) % 24)}</span>
                            }
                        </div>
                        <div className="flex flex-col gap-[10px] items-center">
                            <span className="text-[16px] leading-[24px] font-[400] text-white">Minutes</span>
                            {stakingStatus && <span className="text-[16px] leading-[24px] font-[600] text-white">{(currentTime + Number(stakingStatus[3]) * 1000) - currentTime < 0 ? 0 : Math.floor(((currentTime + Number(stakingStatus[3]) * 1000) - currentTime) / (1000 * 60) % 60)}</span>
                            }
                        </div>
                        <div className="flex flex-col gap-[10px] items-center">
                            <span className="text-[16px] leading-[24px] font-[400] text-white">Seconds</span>
                            {stakingStatus && <span className="text-[16px] leading-[24px] font-[600] text-white">{(currentTime + Number(stakingStatus[3]) * 1000) - currentTime < 0 ? 0 : Math.floor(((currentTime + Number(stakingStatus[3]) * 1000) - currentTime) / 1000 % 60)}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-[10px]">
                    <div className="flex justify-between">
                        <span className="text-[16px] leading-[24px] font-[400] text-white">{Number(stakingTokenBalance)} $SHRX</span>
                        <span className="text-[16px] leading-[24px] font-[400] text-white">{Number(stakingTokenBalance) + Number(stakingRemainingTokens)} $SHRX</span>
                    </div>
                    <div className="relative w-full h-[20px] rounded-[20px] bg-[#FFFFFF99] border border-[#00000020] drop-shadow-lg">
                        <div className="absolute inset-0 rounded-[20px] bg-gradient-to-r from-[#CF7D33] to-[#FAB373]" style={{ width: `${(Number(stakingTokenBalance) / (Number(stakingTokenBalance) + Number(stakingRemainingTokens)) * 100).toFixed(0)}%`, height: "100%" }}></div>
                    </div>
                </div>
                {/* <div className="flex flex-col gap-[20px] items-center">
                    <span className="text-[24px] leading-[36px] font-[400] text-white">1 $SHRX = $0.069</span>
                    <div className="flex gap-[40px] justify-center">
                        <span className="text-[20px] leading-[30px] font-[400] text-white">Bourhgt $SHRX = x</span>
                        <span className="text-[20px] leading-[30px] font-[400] text-white">Stakable $SHRX = x</span>
                    </div>
                </div> */}
                <div className="h-auto md:h-[200px] flex flex-col md:flex-row justify-start md:justify-between gap-[20px] md:gap-[80px]">
                    <div className="w-full flex flex-col gap-[20px] justify-start md:justify-between p-[20px] md:p-[40px] bg-[#FFFFFF33] rounded-[12px] border border-[#00000020] drop-shadow-lg">
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex gap-[20px] justify-between">
                                <span className="text-[14px] md:text-[16px] leading-[18px] font-[400] text-white">StakeAmount</span>
                                <span onClick={() => { setStakeAmount(Number(accountTokenBalance).toFixed(0)) }} className="text-[14px] md:text-[16px] leading-[18px] font-[400] text-white cursor-pointer">Max: {Number(accountTokenBalance).toFixed(0)}</span>
                            </div>
                            <div className="flex">
                                <input value={stakeAmount} onChange={e => setStakeAmount(e.target.value)} type="number" className="w-[200px] bg-transparent focus:outline-none text-[20px] md:text-[24px] leading-[28px] font-[400] text-white" />
                            </div>
                        </div>
                        <div className="flex justify-center">
                            {!(stakingStatus && stakingStatus[0]) && (
                                (Number(stakeAmount) > Number(stakingAllowance)) ?
                                    <button disabled={approvePending} className="w-full flex gap-[10px] justify-center items-center bg-[#000000] px-[20px] py-[10px] rounded-[12px] drop-shadow-lg" onClick={onApprove}>
                                        <span className="text-[16px] leading-[20px] font-[400] text-white bg-[#000000] rounded-[12px] border border-[#00000020] drop-shadow-lg px-[80px] py-[10px]">Approve</span>
                                        {approvePending && <FontAwesomeIcon className="animate-spin text-white" icon={faSpinner} />}
                                    </button> :
                                    <button disabled={!stakingStatus || stakePending} className="w-full flex gap-[10px] justify-center items-center bg-[#000000] px-[20px] py-[10px] rounded-[12px] drop-shadow-lg" onClick={onStake}>
                                        <span className="text-[16px] leading-[20px] font-[400] text-white bg-[#000000] rounded-[12px] border border-[#00000020] drop-shadow-lg px-[80px] py-[10px]">Stake</span>
                                        {stakePending && <FontAwesomeIcon className="animate-spin text-white" icon={faSpinner} />}
                                    </button>
                            )
                            }
                            {stakingStatus && stakingStatus[0] &&
                                <button disabled={!stakingStatus[4] || unstakePending} className="w-full flex gap-[10px] justify-center items-center bg-[#000000] px-[20px] py-[10px] rounded-[12px] drop-shadow-lg" onClick={onUnstake}>
                                    <span className="text-[16px] leading-[20px] font-[400] text-white bg-[#000000] rounded-[12px] border border-[#00000020] drop-shadow-lg px-[80px] py-[10px]">Unstake</span>
                                    {unstakePending && <FontAwesomeIcon className="animate-spin text-white" icon={faSpinner} />}
                                </button>
                            }
                        </div>
                    </div>
                    <div className="w-full flex flex-col gap-[20px] md:gap-0 justify-start md:justify-between px-[20px] md:px-[40px] py-[20px] bg-[#FFFFFF33] rounded-[12px] border border-[#00000020] drop-shadow-lg">
                        <div className="flex justify-center">
                            <span className="text-[24px] leading-[36px] font-[600] text-white">Returns</span>
                        </div>
                        <div className="flex justify-center">
                            <div className="relative w-full h-[44px] flex gap-[10px] justify-center rounded-[12px] bg-[#FFFFFF33] border border-[#00000020] drop-shadow-lg">
                                {stakeDetails.map((stakeDetail, index) => (
                                    <span onClick={() => setSelectedStaking(index)} className={`text-[14px] md:text-[16px] leading-[24px] font-[400] text-white ${selectedStaking == index ? "bg-[#000000]" : ""} rounded-[12px] px-[10px] md:px-[40px] py-[10px] cursor-pointer`}>{stakeDetail.stakePercentLabel}</span>
                                ))}
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="flex flex-col md:flex-row gap-[10px] items-center">
                                <span className="text-[14px] md:text-[18px] leading-[28px] font-[600] text-white">Locking for</span>
                                <span className="text-[14px] md:text-[18px] leading-[28px] font-[400] text-white">{stakeDetails[selectedStaking].stakePeriodLabel}</span>
                            </div>
                            <div className="flex flex-col md:flex-row gap-[10px] items-center">
                                <span className="text-[14px] md:text-[18px] leading-[28px] font-[600] text-white">Estimated Return</span>
                                <span className="text-[14px] md:text-[18px] leading-[28px] font-[400] text-white">{(Number(stakeAmount) / 100 * (100 + Number(stakeDetails[selectedStaking].stakePercent))).toFixed(0)} $SHRX</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <div className="w-full md:w-1/3 flex">
                        <CustomConnectButton />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StakingBox;
