import React, { useEffect, useState, useContext } from "react";
import PresaleBox from "../components/PresaleBox";
import AboutBox from "../components/AboutBox";
import StakingBox from "../components/StakingBox";
import TokenomicsBox from "../components/TokenomicsBox";
import RoadmapBox from "../components/RoadmapBox";

const Home = () => {

  return (
    <div className="font-poppins w-full flex flex-col gap-[40px] items-center bg-[#0E0600]">
      <div id="home" className="relative flex w-full">
        <img className="absolute top-0 left-0 object-cover w-full h-full" src="images/home_bg.png" />
        <div className="absolute left-0 bottom-0 w-full h-[200px] bg-gradient-to-t from-[#000000ff] to-[#00000000]"></div>
        <div className="absolute left-0 top-0 w-full h-[200px] bg-gradient-to-b from-[#000000ff] to-[#00000000]"></div>
        <div className="w-full min-h-[100vh] flex justify-center bg-[#00000090] py-[200px] md:py-0 z-10">
          <div className="max-w-[1400px] w-full flex flex-col md:flex-row gap-[40px] md:gap-0 justify-center px-[20px]">
            <div className="flex items-center w-full md:w-1/2">
              <PresaleBox />
            </div>
            <div className="order-first md:order-last w-full md:w-1/2 flex flex-col gap-[40px] justify-center">
              <span className="text-[48px] leading-[58px] font-[600] text-white text-center md:text-left">The mother of all meme coins emerges from the mythical jungle</span>
              <span className="text-[20px] leading-[30px] font-[400] text-white text-center md:text-left">a foundation crafted for any token to take root. Whispered among the ancient vines, it’s said this path will be the swiftest and most effortless, evolving beyond its layers to become a realm all its own.</span>
              <a href="https://bscscan.com/token/0x2409a6895a9e2199c379de8da426f0683b4064b9#balances" 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 className="text-[16px] sm:text-[18px] md:text-[20px] leading-[24px] sm:leading-[27px] md:leading-[30px] font-[400] text-white text-center md:text-left hover:underline break-all">
                <b>0x2409a6895A9e2199c379de8dA426f0683B4064B9</b>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="about" className="relative w-full flex justify-center py-[80px]">
        <div className="max-w-[1400px] w-full flex flex-col md:flex-row px-[20px] z-10">
          <AboutBox />
        </div>
        <img className="absolute right-0 h-[1000px] object-contain" src="images/ellipse1.png" />
      </div>
      <div id="staking" className="relative w-full flex justify-center py-[80px]">
        <div className="max-w-[1400px] w-full flex flex-col gap-[80px] px-[20px] z-10">
          <div className="flex flex-col md:flex-row gap-[20px] justify-start md:justify-between items-center">
            <span className="text-[64px] leading-[96px] font-[600] text-white">Staking</span>
            {/* <div className="relative w-[350px] h-[60px]">
              <div
                className="absolute inset-0 rounded-[12px] bg-gradient-to-br from-[#FFFFFFCF] via-transparent to-[#CF7D33ff]"
              ></div>
              <div
                className={`relative flex justify-center items-center m-[3.5px] bg-[#ffffff] backdrop-blur-md rounded-[8.5px] px-[20px] py-[10px] bg-opacity-10 border border-[#00000020] drop-shadow-lg`}
              >
                <button className="text-[20px] leading-[30px] font-[400] text-white">Withdraw Staked $SHRX</button>
              </div>
            </div> */}
          </div>
          <StakingBox />
        </div>
        <img className="absolute left-0 h-[1000px] object-contain" src="images/ellipse2.png" />
      </div>
      <div id="tokenomics" className="relative w-full flex justify-center py-[80px]">
        <div className="max-w-[1400px] w-full flex flex-col gap-[80px] px-[20px] z-10">
          <div className="flex justify-center">
            <span className="text-[64px] leading-[96px] font-[600] text-white">Tokenomics</span>
          </div>
          <div className="flex flex-col md:flex-row gap-[40px] md:gap-[80px] justify-between">
            <div className="flex w-full md:w-1/2">
              <TokenomicsBox />
            </div>
            <div className="flex items-center w-full md:w-1/2">
              <span className="text-[20px] leading-[30px] font-[400] text-white text-center md:text-left">In the heart of a mythical jungle, where legends take shape, emerges the mother of all meme coins—a token of boundless potential. Born from the vision of creating the ultimate infrastructure for all tokens, it carries whispers of a journey to become the fastest and most affordable path for growth. As its roots deepen and its branches spread, this project is destined to evolve into a network unlike any other, one that stands tall and mighty, paving the way for a new realm of possibilities.</span>
            </div>
          </div>
        </div>
      </div>
      <div id="roadmap" className="relative w-full flex justify-center py-[80px]">
        <div className="max-w-[1400px] w-full flex flex-col gap-[80px] px-[20px] z-10">
          <div className="flex justify-center">
            <span className="text-[64px] leading-[96px] font-[600] text-white">Roadmap</span>
          </div>
          <div className="flex">
            <RoadmapBox />
          </div>
          {/* <div className="flex flex-col gap-[20px] items-center md:hidden">
            <span className="text-[18px] leading-[24px] font-[600] text-white">Presale and Staking</span>
            <span className="text-[18px] leading-[24px] font-[600] text-white">List on CEX</span>
            <span className="text-[18px] leading-[24px] font-[600] text-white">List on DEX</span>
            <span className="text-[18px] leading-[24px] font-[600] text-white">Create Layer-2</span>
            <span className="text-[18px] leading-[24px] font-[600] text-white">Create CEX</span>
            <span className="text-[18px] leading-[24px] font-[600] text-white">Get Memecoins To Thrive</span>
          </div> */}
        </div>
      </div>
      <div className="relative w-full flex justify-center py-[80px]">
        <div className="max-w-[1400px] w-full flex flex-col gap-[80px] px-[20px] z-10">
          <div className="flex justify-center">
            <span className="text-[40px] md:text-[64px] leading-[96px] font-[600] text-white">To Be Continued</span>
          </div>
          <div className="flex gap-[80px] justify-center">
            <a href="https://t.me/sherexcoin" target="_blank" rel="noopener noreferrer"><svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52C40.3594 52 52 40.3594 52 26C52 11.6406 40.3594 0 26 0ZM38.4062 17.8281L34.1719 37.7031C33.8438 39.1406 32.9688 39.5 31.7656 38.8281L25.3438 34.125L22.2656 37.0625C21.9062 37.4219 21.6094 37.7188 20.9062 37.7188L21.3906 31.1719L33.5938 20.1719C34.1406 19.6875 33.4688 19.4219 32.7344 19.9062L17.7344 29.4844L11.3906 27.5C9.98438 27.0781 9.95312 26.0781 11.6719 25.4062L36.5938 15.7812C37.7656 15.3906 38.7969 16.125 38.4062 17.8281Z" fill="white"/>
            </svg></a>
            <a href="https://x.com/sherexcoin" target="_blank" rel="noopener noreferrer"><svg width="55" height="52" viewBox="0 0 55 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.600728 0.248535L21.655 28.4L0.467773 51.2884H5.23616L23.7855 31.2493L38.7729 51.2884H54.9999L32.761 21.5534L52.4819 0.248535H47.7135L30.6305 18.7042L16.8277 0.248535H0.600728ZM7.61299 3.76093H15.0677L47.9865 47.7754H40.5318L7.61299 3.76093Z" fill="white" />
            </svg></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
